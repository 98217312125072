@import "@/assets/scss/style.scss";

.mb-10 {
    margin-bottom: 10px;
}
.iconButton {
    display: inline-flex;
    width: 24px;
    height: 24px;
    align-self: flex-end;
    cursor: pointer;
    margin-left: 8px;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
    opacity: 0;
}
.card {
    padding: 16px;
    margin-bottom: 24px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 12px 0px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
}
